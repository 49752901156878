// AboutPage.js

import React from 'react';

const AboutPage = () => {
  return (
    <div className="dark:bg-black text-white min-h-screen">
      <div className="container mx-auto p-8">
        <h1 className="text-4xl font-bold mb-8">About WOS Project</h1>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">React Website & Reading Platform</h2>
          <p>Embark on a journey of knowledge with our user-friendly React website, providing an immersive book-reading experience.</p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">Video Sending and Computer Commands</h2>
          <p>Empower users with the ability to send videos and execute commands on their computers remotely, enhancing control and flexibility.</p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">Creation of WOSDB (C) + Website/App/Compression</h2>
          <p>WOSDB, crafted in C, serves as the backbone for data management. The project extends to a versatile website, mobile app, and compression algorithms.</p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">Computer Vision (Motion and Detection/Surveillance)</h2>
          <p>Experience advanced computer vision with motion detection and surveillance capabilities, elevating security measures.</p>
        </section>


        {/* ... Add more sections for each aspect of the WOS Project ... */}

        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">Encryption/Decryption (C)</h2>
          <p>Ensure data security with robust encryption and decryption mechanisms written in C, preserving the confidentiality and integrity of your data.</p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">Algorithm Showcase (C/JS/TS/Java)</h2>
          <p>Witness a showcase of powerful algorithms implemented in C, JavaScript (JS), TypeScript (TS), and Java, demonstrating excellence in various programming languages.</p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">Dashboard (React TS) + Mobile App + Notes</h2>
          <p>Experience a sophisticated Dashboard developed in React TypeScript, complemented by a feature-rich mobile app. Take notes seamlessly and stay organized with WOS Project's intuitive interfaces.</p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">Machine Learning Integration</h2>
          <p>Explore the realms of Machine Learning (ML) as WOS Project integrates powerful ML algorithms, unlocking new possibilities and enhancing the project's capabilities.</p>
        </section>


      </div>
    </div>
  );
};

export default AboutPage;
