import React, { useEffect, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import rehypeHighlight from 'rehype-highlight';
import remarkGfm from 'remark-gfm';
import rehypeRaw from 'rehype-raw';

import { dark } from 'react-syntax-highlighter/dist/esm/styles/prism';

const WosCVPage = () => {
  const [mark, setMark] = useState('');

  useEffect(() => {
    const fetchMarkdown = async () => {
      try {
        const response = await fetch('https://raw.githubusercontent.com/Mattiwos/wosCV/main/README.md');
        const data = await response.text();
        setMark(data);
        console.log(data);
      } catch (error) {
        console.error('Error fetching markdown:', error);
      }
    };

    fetchMarkdown();
  }, []);

  return (
    <div className="flex justify-center items-center dark:bg-black text-white min-h-screen">
      <div className="container mx-auto flex">
        <div className="w-1/2 p-6">
          <h1 className="text-center text-4xl font-bold mb-4">wosCV</h1>
          <p className="mb-3 text-gray-500 dark:text-gray-400">
          wosCV is a comprehensive project that encompasses motion detection, surveillance with pattern analysis, driving assistance for self-driving vehicles, and 3D mapping capabilities. 
          </p>
          <p className="mb-3 text-gray-500 dark:text-gray-400">
          wosCV includes motion detection capabilities, allowing it to identify and track movements.</p>
            <p className="mb-3 text-gray-500 dark:text-gray-400">
            In the surveillance domain, wosCV collects data and utilizes machine learning to draw patterns from people's movements. This suggests an ability to analyze and interpret human behavior for security or observational purposes..</p>
            <p className="mb-3 text-gray-500 dark:text-gray-400">
            You can follow the progress of the project on the side or check out the github and make contributions.</p>
            <a href="https://github.com/mattiwos/wosCV" className="inline-flex items-center justify-center px-5 py-3 mr-3 text-base font-medium text-center text-white rounded-lg bg-primary-700 hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 dark:focus:ring-primary-900">
                    <button className="inline-flex items-center justify-center px-7 py-5 mr-3 text-base font-large text-center bg-red-700 hover:bg-red-900 font-bold rounded-full">
                        checkout GitHub
                        <svg className="w-5 h-5 ml-2 -mr-1" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                    </button>
                </a>
        </div>

        <div className="w-1/2 max-h-screen overflow-y-scroll bg-gray-300 p-6 rounded-md shadow-md">
          <div className="prose prose-xl" style={dark}>
            <ReactMarkdown style={dark} remarkPlugins={[remarkGfm, rehypeHighlight]} rehypePlugins={[rehypeRaw]}>
              {mark}
            </ReactMarkdown>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WosCVPage;
