// LearnMore.js
import React from 'react';

function LearnMore() {
  return (
      <div className="bg-indigo-900 min-h-screen from-gray-100 to-gray-300">
                <div className="container py-10 px-10 mx-0 min-w-full flex flex-col items-center">
            {/* <h1 className="mb-4 text-3xl font-extrabold text-gray-900 dark:text-white md:text-5xl lg:text-6xl"><span className="text-transparent bg-clip-text bg-gradient-to-r to-rose-600 from-lime-400">LearnMore</span></h1> */}
            <h1 className="text-6xl lg:text-7xl xl:text-8xl text-gray-200 tracking-wider font-bold font-serif mt-12 text-center">Coming Soon</h1>

            </div>
    
      </div>
  );
}

export default LearnMore;
